import Input from '../../Field/Input/Input'
import Textarea from '../../Field/Textarea/Textarea'
import { StyledField } from '../styles'
import { StyledContactFieldHalf } from './styles'
import {
  requiredValidator,
  emailsEqualValidator,
} from '../../../common/validators'
import { makeInitialValues } from '../../../common/utils'

export const fields = [
  {
    wrapper: StyledContactFieldHalf,
    component: Input,
    id: 'firstName',
    label: 'First Name',
    name: 'firstName',
    placeholder: 'Type your first name',
    validate: requiredValidator,
  },
  {
    wrapper: StyledContactFieldHalf,
    component: Input,
    id: 'lastName',
    label: 'Last Name',
    name: 'lastName',
    placeholder: 'Type your last name',
    validate: requiredValidator,
  },
  {
    wrapper: StyledContactFieldHalf,
    component: Input,
    id: 'email',
    label: 'Email',
    name: 'email',
    type: 'email',
    placeholder: 'Type your email',
    validate: requiredValidator,
  },
  {
    wrapper: StyledContactFieldHalf,
    component: Input,
    id: 'confirmEmail',
    label: 'Confirm email',
    name: 'confirmEmail',
    type: 'email',
    placeholder: 'Confirm your email',
    validate: emailsEqualValidator,
  },
  {
    wrapper: StyledField,
    component: Textarea,
    id: 'message',
    label: 'Message',
    name: 'message',
    placeholder: 'Leave a message',
    validate: requiredValidator,
  },
]

export const initialValues = makeInitialValues(fields)
