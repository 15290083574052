import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Container from '../components/Container'
import TruncatedHero from '../components/Heros/TruncatedHero/TruncatedHero'
import Contact from '../components/Contact/Contact'
import Inner from '../components/Inner'
import { Heading2 } from '../components/Typography/Typography'
import ContactForm from '../components/Forms/ContactForm/ContactForm'
import HTMLBody from '../components/HTMLBody'
import {
  TRUNCATED_HERO_TYPES,
  TRUNCATED_HERO_BOX_SIDE_TYPES,
  TRUNCATED_HERO_GRADIENT_TYPES,
} from '../constants/truncatedHeroTypes'
import { BG_TYPES } from '../constants/bgTypes'
import { responsiveImage } from '../common/utils'

const ContactPage = ({ data }) => {
  const page = data.wp.page?.contactAcf
  const seoData = data.wp.page?.seo

  const heroImages = responsiveImage(page.heroImgMobile, page.heroImg)

  return (
    <Layout>
      <SEO title={seoData?.title} data={seoData} />
      <Container bg={BG_TYPES.black}>
        <TruncatedHero
          heroType={TRUNCATED_HERO_TYPES.withGradientOnMobile}
          heroBoxSideType={TRUNCATED_HERO_BOX_SIDE_TYPES.left}
          heroImages={heroImages}
          gradient={TRUNCATED_HERO_GRADIENT_TYPES.top}
        >
          <Container
            mt={['-8rem', '', '25.875rem']}
            position={['', '', 'absolute']}
            top="0"
            left="0"
          >
            <Contact
              header={
                <Heading2 as="h1" color="white">
                  {page.boxTitle}
                </Heading2>
              }
              subtitle={
                <HTMLBody
                  opacity="1"
                  fontWeight="bold"
                  html={page.boxSubtitle}
                />
              }
              content={<HTMLBody opacity="1" html={page.boxContent} />}
              phone={page.phone}
              mail={page.email}
            />
          </Container>
        </TruncatedHero>
        <Container>
          <Inner>
            <ContactForm title={page.formTitle} />
          </Inner>
        </Container>
      </Container>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query {
    wp {
      page(id: "contact", idType: URI) {
        id
        title
        contactAcf {
          heroImg {
            mediaItemUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          heroImgMobile {
            mediaItemUrl
            imageFile {
              childImageSharp {
                fluid(maxWidth: 375) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          boxTitle
          boxSubtitle
          boxContent
          phone
          email
          formTitle
        }
        seo {
          ...seoFragment
        }
      }
    }
  }
`
