import React from 'react'

import { IconWrapper } from '../../common/mixins'

const EnvelopeIcon = () => (
  <IconWrapper fontSize="0.75">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 500 375"
      fill="currentColor"
    >
      <path d="M490.5 123.8c3.8-3 9.5-.2 9.5 4.6v199.7c0 25.9-21 46.9-46.9 46.9H46.9C21 375 0 354 0 328.1V128.5c0-4.9 5.6-7.6 9.5-4.6 21.9 17 50.9 38.6 150.5 110.9 20.6 15 55.4 46.7 90 46.5 34.9.3 70.3-32 90.1-46.5 99.6-72.3 128.6-94 150.4-111zM250 250c22.7.4 55.3-28.5 71.7-40.4 129.6-94 139.5-102.2 169.3-125.7 5.7-4.4 9-11.2 9-18.5V46.9C500 21 479 0 453.1 0H46.9C21 0 0 21 0 46.9v18.6c0 7.2 3.3 14 9 18.5 29.9 23.3 39.7 31.6 169.3 125.7 16.4 11.8 49 40.7 71.7 40.3z" />
    </svg>
  </IconWrapper>
)

export default EnvelopeIcon
