import React from 'react'
import { Form, Field } from 'react-final-form'
import PropTypes from 'prop-types'

import {
  StyledContactFormWrapper,
  StyledContactFormSentMsg,
  StyledContactFormContent,
  StyledContactFormAction,
} from './styles'
import { StyledForm, StyledFormHeader, StyledFormLoader } from '../styles'
import Button from '../../Buttons/Button/Button'
import { Body, Typo3 } from '../../Typography/Typography'
import { BUTTON_TYPES } from '../../../constants/buttonTypes'
import { FIELD_TYPES } from '../../../constants/fieldTypes'
import { fields, initialValues } from './mock'

import { useOnSubmitHandler } from '../form'

const ContactForm = ({ title }) => {
  const { isLoading, isSent, onSubmit, message } = useOnSubmitHandler(
    'ajax_save_form_submission',
  )

  return (
    <StyledContactFormWrapper isSent={isSent} className="uiAnimBottom">
      <Form
        {...{ initialValues }}
        onSubmit={onSubmit}
        render={({ handleSubmit, values }) => (
          <StyledForm onSubmit={handleSubmit} isLoading={isLoading}>
            {isSent ? (
              <StyledContactFormSentMsg>
                <Body fontWeight="bold" color="success" opacity="1">
                  {message ? message : 'Your message was sent successfully.'}
                </Body>
              </StyledContactFormSentMsg>
            ) : (
              <>
                <StyledFormHeader>
                  <Typo3>{title}</Typo3>
                </StyledFormHeader>
                <StyledContactFormContent>
                  {fields.map(
                    ({
                      wrapper: Wrapper,
                      component: Component,
                      id,
                      name,
                      title,
                      label,
                      placeholder,
                      type,
                      validate,
                    }) => (
                      <Wrapper key={id}>
                        <Field
                          name={name}
                          validate={!!validate && validate(values)}
                        >
                          {({ input, meta }) => (
                            <Component
                              fieldType={FIELD_TYPES.dark}
                              id={id}
                              label={label}
                              title={title}
                              placeholder={placeholder}
                              type={type}
                              validate={meta.touched && meta.error}
                              {...input}
                            />
                          )}
                        </Field>
                      </Wrapper>
                    ),
                  )}
                </StyledContactFormContent>

                <StyledContactFormAction>
                  <Button
                    type="submit"
                    aria-label="Submit"
                    btnType={BUTTON_TYPES.dark}
                  >
                    Submit
                  </Button>
                </StyledContactFormAction>
              </>
            )}
          </StyledForm>
        )}
      />
      {isLoading && <StyledFormLoader />}
    </StyledContactFormWrapper>
  )
}

export default ContactForm

ContactForm.propTypes = {
  title: PropTypes.string,
}
