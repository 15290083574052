import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledContact,
  StyledContactHeader,
  StyledContactContent,
  StyledContactDetails,
  StyledContactIcon,
  StyledContactLink,
} from './styles'
import EnvelopeIcon from '../Icons/EnvelopeIcon'
import PhoneIcon from '../Icons/PhoneIcon'
import { Body } from '../Typography/Typography'

const Contact = ({ header, subtitle, content, phone, mail }) => (
  <StyledContact className="uiAnimCustomBottom">
    <StyledContactHeader>{header}</StyledContactHeader>
    <StyledContactContent>
      {subtitle}
      {content}
    </StyledContactContent>
    <StyledContactDetails>
      <StyledContactLink href={`tel:${phone.replace(/\s/g, '')}`}>
        <StyledContactIcon>
          <PhoneIcon />
        </StyledContactIcon>
        <Body opacity="0.9">{phone}</Body>
      </StyledContactLink>
      <StyledContactLink href={`mailto:${mail}`}>
        <StyledContactIcon>
          <EnvelopeIcon />
        </StyledContactIcon>
        <Body opacity="0.9" textDecoration="underline">
          {mail}
        </Body>
      </StyledContactLink>
    </StyledContactDetails>
  </StyledContact>
)

export default Contact

Contact.propTypes = {
  header: PropTypes.node.isRequired,
  subtitle: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  phone: PropTypes.string.isRequired,
  mail: PropTypes.string.isRequired,
}
