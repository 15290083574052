import styled from 'styled-components'

import media from '../../../common/MediaQueries'
import {
  StyledFormContent,
  StyledFormSentMsg,
  StyledFieldHalf,
} from '../styles'

const StyledContactFormWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  background: ${({ theme }) => theme.colors.white};
  margin-left: -1.875rem;
  margin-right: -1.875rem;
  padding: 3.125rem 1.875rem;
  width: calc(100% + 1.875rem * 2);
  clip-path: polygon(0 0, 100% 2.5rem, 100% 100%, 0% 100%);

  @media ${media.tabletSmall} {
    margin: -7.5rem 0 0 50%;
    max-width: 34.8125rem;
    width: auto;
    padding: 6rem 1.5rem 3.125rem 1.5rem;
    min-height: 25rem;
    clip-path: none;
  }

  @media ${media.tablet} {
    margin-left: 33.125rem;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
  }

  @media ${media.desktop} {
    margin-left: auto;
  }
`

const StyledContactFormContent = styled(StyledFormContent)`
  margin-bottom: 1.75rem;
`

const StyledContactFieldHalf = styled(StyledFieldHalf)`
  @media ${media.tablet} {
    width: calc(50% - 0.5rem);
  }
`

const StyledContactFormAction = styled.div`
  display: flex;
  justify-content: center;

  button {
    width: 100%;
  }
`

const StyledContactFormSentMsg = styled(StyledFormSentMsg)`
  padding-top: 2rem;
`

export {
  StyledContactFormWrapper,
  StyledContactFormContent,
  StyledContactFieldHalf,
  StyledContactFormSentMsg,
  StyledContactFormAction,
}
