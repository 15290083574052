import React from 'react'

import { IconWrapper } from '../../common/mixins'

const PhoneIcon = () => (
  <IconWrapper fontSize="0.9375">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 448"
      fill="currentColor"
    >
      <path d="M400 0H48C21.5 0 0 21.5 0 48v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V48c0-26.5-21.5-48-48-48zm-16.4 307.4l-15 65C367 379.2 361 384 354 384 194 384 64 254.3 64 94c.2-6.9 5-12.8 11.6-14.6l65-15c1.1-.2 2.2-.4 3.4-.4 5.9.3 11.2 3.8 13.8 9.1l30 70c.7 1.9 1.1 3.9 1.2 5.9-.2 4.4-2.2 8.6-5.5 11.6l-37.9 31a232 232 0 00110.8 110.8l31-37.9c3-3.3 7.2-5.3 11.6-5.5 2 .1 4 .5 5.9 1.2l70 30c5.3 2.6 8.8 7.9 9.1 13.8 0 1.1-.2 2.3-.4 3.4z" />
    </svg>
  </IconWrapper>
)

export default PhoneIcon
