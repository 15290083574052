import styled from 'styled-components'

import { transition, decorLink, decorHoverLink } from '../../common/mixins'
import media from '../../common/MediaQueries'

const StyledContact = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
  padding: 0 1.875rem 3.125rem 1.875rem;

  @media ${media.tabletSmall} {
    padding: 3.125rem 1.5rem;
    width: calc(49vw - 1.875rem);
    background: ${({ theme }) => theme.colors.black};
  }

  @media ${media.tablet} {
    width: auto;
    max-width: 33.125rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
`

const StyledContactHeader = styled.div`
  margin-bottom: 2rem;
`

const StyledContactContent = styled.div`
  margin-bottom: 1.5rem;

  p + p {
    margin-top: 1.5rem;
  }

  a,
  strong {
    font-weight: ${({ theme }) => theme.fontWeights.bold};
  }

  a {
    color: ${({ theme }) => theme.colors.yellow};
    ${decorLink};
    ${transition}

    &:hover {
      ${decorHoverLink};
    }
  }

  @media ${media.tabletSmall} {
    margin-bottom: 3.375rem;
  }
`

const StyledContactDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  a {
    color: ${({ theme }) => theme.colors.yellow};
    ${transition}

    &:hover {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`

const StyledContactIcon = styled.span`
  display: flex;
  flex-shrink: 0;
  width: 1rem;
  margin-right: 0.75rem;

  span {
    max-width: 100%;
  }
`

const StyledContactLink = styled.a`
  display: flex;
  align-items: center;

  & + & {
    margin-top: 0.25rem;
  }
`

export {
  StyledContact,
  StyledContactHeader,
  StyledContactContent,
  StyledContactDetails,
  StyledContactIcon,
  StyledContactLink,
}
